.tabs {
  position: relative;
  display: flex;
  z-index: 1;
  padding-left: 0;
  margin: 0;
  overflow: auto;
}

.tabs button {
  display: block;
  user-select: none;
  text-align: center;
  background-color: transparent;
  line-height: 30px;
  font-size: 13px;
  margin-left: 10px;
  cursor: pointer;
  border: none;
}

.tabs.tabs-pills button {
  color: #575757;
  white-space: nowrap;
  width: 100px;
  font-weight: 600;
  font-size: 13px;
  width: auto;
  padding: 3px 10px;
  border-radius: 6px;
}

.tabs.tabs-pills button.active {
  background-color: hsl(220, 15%, 90%);
}

.tabs.tabs-folder button {
  border-radius: 6px 6px 0 0;
  color: #575757;
  white-space: nowrap;
  width: 105px;
  background-color: #d7dade;
}

.tabs.tabs-folder button.active {
  background-color: #fff;
}

.transition.ease-in.duration-100 {
  transition: all ease-in 100ms;
}

.opacity-100 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.mobile-tab {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin: -10px;
  padding: 10px;
  border-radius: 6px;
  padding: 10px 40px 10px 12px;
  border: none;
  background: transparent;
  color: #575757;
  font-size: 14px;
  line-height: 1.42857143;
}

.mobile-tab.tabs-folder {
  position: relative;
  background-color: #d7dade;
  border: 1px solid #575757;
  padding: 10px 20px;
  border-radius: 6px;
  margin-bottom: 10px;
}

.mobile-tab svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.mobile-tab-options {
  top: 100%;
  left: 0;
  border: 0;
  border-radius: 6px;
  z-index: 10;
  display: block;
  background-color: #fff;
  position: absolute;
  width: 100%;
  max-width: 300px;
  margin: 0;
  padding: 4px 0;
  margin-top: 6px;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  font-size: 14px;
  line-height: 20px;
}

.mobile-tab-options li {
  position: relative;
  box-sizing: border-box;
  display: list-item;
  padding: 8px 16px 8px 40px;
  list-style: none;
}

.mobile-tab-options li.hovered {
  background-color: hsl(220, 80%, 97%);
}

.mobile-tab-options li.selected {
  color: hsl(220, 40%, 35%);
  background-color: hsl(220, 100%, 93%);
  font-weight: 600;
}

.mobile-tab-options li svg {
  color: hsl(220, 40%, 45%);
  position: absolute;
  stroke-width: 4px;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
}

@media (max-width: 600px) {
  .tabs {
    flex-wrap: wrap;
    justify-content: center;
  }
}
