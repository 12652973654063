body {
  overflow-y: auto;
}

.chat-header h4 {
  display: flex;
  align-items: center;
}

.tickets-nexus {
  background-color: white;
}
/* 
ul.tickets-nexus {
  width: 800px;
} */
