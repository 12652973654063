nav {
  width: 100%;
  background-color: hsl(220, 30%, 20%);
  height: 61px;
  transition: height 300ms ease-in-out;
}

.nav-search-select {
  position: relative;
  width: 100%;
  z-index: 3;
}

.nav-search-select > div {
  width: 100%;
}

.nav-search-select > div > div:first-of-type {
  background-color: hsl(217, 19%, 27%);
  border: none;
  height: 40px;
  border-radius: 6px;
  outline: 0;
  box-shadow: none !important;
  padding-right: 40px;
  padding-left: 10px;
}

.nav-search-select > div > div:first-of-type > div:first-of-type > div {
  color: #d1d5db;
}
.nav-search-select > div > div:first-of-type > div:first-of-type > div[class*="placeholder"] {
  color: hsl(216, 12%, 65%);
}

.nav-search-select > div > div:first-of-type > div:nth-of-type(2) {
  display: none;
}

nav.open {
  height: 240px;
}

nav .container > div {
  box-sizing: content-box;
  border-bottom: 1px solid hsl(217, 20%, 25%);
  margin: 0;
}

nav .nav-content {
  display: flex;
  justify-content: space-between;
  height: 60px;
}

nav .nav-left {
  display: flex;
  align-items: center;
}

nav .nav-left h1 {
  font-family: "Lato", sans-serif;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 2px;
}

.nav-pages {
  margin: 0 40px;
  align-items: baseline;
}

nav .nav-left .nav-pages {
  display: none;
}

.nav-pages a {
  padding: 6px 12px;
  margin-left: 16px;
  color: hsl(216, 12%, 84%);
  user-select: none;
  cursor: pointer;
  line-height: 20px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 6px;
  border: none;
  background-color: transparent;
  outline: 0;
}

.nav-pages a:hover:not(.active) {
  color: #fff;
  background-color: hsl(217, 19%, 27%);
}

.nav-pages a:first-of-type {
  margin-left: 0;
}

.nav-pages a.active {
  color: #fff;
  background-color: hsl(221, 39%, 11%);
}

nav .nav-left .nav-search {
  display: none;
}

nav .nav-right {
  display: flex;
  align-self: center;
}

nav .nav-right .nav-search-select {
  display: none;
}

nav .nav-right .actions {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

nav .nav-right .actions .alerts {
  background: transparent;
  border: none;
  color: hsl(216, 12%, 84%);
  font-size: 20px;
  padding: 5px;
  height: 30px;
}

nav .nav-right .actions .user {
  height: 35px;
  width: 35px;
  margin-left: 12px;
  border: none;
  border-radius: 50%;
  background-color: hsl(217, 75%, 65%);
}

nav .nav-right .actions .menu-button {
  background-color: hsl(215, 28%, 17%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 6px;
}

nav .nav-right .actions .menu-button:hover,
nav.open .nav-right .actions .menu-button {
  background-color: hsl(221, 39%, 11%);
}

nav .nav-right .actions .menu-button svg {
  font-size: 20px;
  color: hsl(216, 12%, 84%);
}

nav .mobile-menu {
  grid-template-columns: 1fr;
  justify-content: center;
  display: grid;
  gap: 20px;
  overflow: hidden;
  height: 0;
  padding-bottom: 0;
  transition: all 300ms ease-in-out;
}

nav.open .mobile-menu {
  padding-bottom: 20px;
  height: 172px;
  overflow: visible;
  animation: overflow-visible 300ms ease-in-out;
}

@keyframes overflow-visible {
  0% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}

nav .mobile-menu .nav-pages {
  display: flex;
  justify-content: center;
  margin: 0;
  grid-column: 1 / -1;
}

nav .mobile-menu .nav-search input {
  width: 100%;
}

nav .nav-search {
  position: relative;
}

nav .nav-search input {
  width: 250px;
  height: 40px;
  background-color: hsl(217, 19%, 27%);
  border: none;
  border-radius: 6px;
  padding: 0 40px 0 20px;
  outline: 0;
  transition: all 300ms ease-in-out;
  color: hsl(216, 12%, 84%);
}

nav .nav-search input::placeholder {
  color: hsl(216, 12%, 65%);
}

nav .nav-search button,
nav .nav-search-select button {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(calc(-50% + 1px));
  transition: opacity 200ms ease-in-out;
  cursor: pointer;
  height: 13.5px;
  width: 13.5px;
  background-color: transparent;
  padding: 0;
  border: none;
  outline: 0;
}

nav .nav-search svg > *,
nav .nav-search-select svg > * {
  color: hsl(216, 12%, 84%);
}

.header-extended {
  background-color: hsl(220, 30%, 20%);
}

@media (min-width: 600px) {
  .nav-search-select {
    width: 250px;
  }

  nav.open {
    height: 175px;
  }

  nav .mobile-menu {
    grid-template-columns: auto auto;
  }

  nav.open .mobile-menu {
    height: 110px;
  }

  nav .mobile-menu .nav-pages {
    justify-content: flex-start;
  }

  nav .mobile-menu .nav-search input {
    width: 250px;
  }
}

@media (min-width: 1200px) {
  nav.open {
    height: 60px;
  }
  nav .nav-left .nav-pages {
    display: flex;
  }

  nav .nav-left .nav-search,
  nav .nav-right .nav-search,
  nav .nav-right .nav-search-select {
    display: block;
  }

  nav .nav-right .actions .menu-button {
    display: none;
  }

  nav.open .mobile-menu {
    display: none;
  }
}
