.table {
  width: 100%;
  border-radius: 6px;
  /* overflow: hidden; */
  font-family: "Noto Sans", sans-serif;
  color: #8e8e93;
  background-color: #fff;
  font-size: 14px;
  border-collapse: collapse;
}

.table .text-left{
  text-align: left !important;
}

body > tr {
  display: table-row;
}

tr {
  border-bottom: 1px solid hsl(220, 13%, 91%);
}

th,
td {
  font-weight: 400;
  padding: 3px 10px;
  color: hsl(220, 9%, 46%);
  white-space: nowrap;
}

.text-gray-500 {
  color: hsl(220, 9%, 46%) !important;
}

thead tr:first-of-type th,
thead tr:first-of-type th {
  border-top: 0;
}

.card th:first-of-type,
.card td:first-of-type {
  border-left: none;
}

.card th:last-of-type,
.card td:last-of-type {
  border-right: none;
}

.card tbody tr:last-of-type th,
.card tbody tr:last-of-type td {
  border-bottom: none;
}

th {
  background-color: hsl(220, 20%, 98%);
  color: hsl(220, 39%, 11%);
  position: relative;
  padding: 5px 10px;
  text-align: left;
}

.card thead tr:first-of-type {
  position: sticky;
  top: 0;
  z-index: 1;
}

th-th {
  padding-right: 20px;
}

th.th-filter {
  position: sticky;
  border: 0;
  top: -1px;
  background-color: #fff;
  z-index: 1;
}

td.text-red {
  color: #e00606;
}

.text-red td {
  color: #e00606;
}

.text-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sort-icon {
  position: absolute;
  right: 5px;
}

.sort-icon i::after {
  font-size: 12px;
  width: 10px;
  height: 10px;
  color: rgb(22, 157, 224);
}

.finalizado td:not(.actions-col) {
  color: #08b308;
}

.eliminado td:not(.actions-col) {
  color: #da0000;
}

.rs-btn {
  margin-right: 5px;
}

.actions-col {
  width: 110px;
  padding: 2px 10px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

tr:hover td {
  background-color: #f7f7fa;
}

.th-filter {
  padding: 0 0 1px 0;
  overflow: hidden;
}

.th-filter div {
  height: 33px;
}

.th-filter.shadow {
  padding: 0 0 4px 0;
}

.th-filter.shadow div {
  border-top: 1px solid #f2f2f5;
  box-shadow: -1px 2px 3px 1px #00000026;
}

th.th-filter input {
  position: absolute;
  width: calc(100% - 6px);
  top: 0;
  left: 3px;
  padding: 5px 11px;
  margin-top: 3px;
  font-size: 14px;
  line-height: 1;
}

td.order-td {
  margin: 0;
  padding: 0;
  width: 80px;
}

td.order-td input {
  text-align: center;
  width: 100%;
  line-height: 1;
  padding: 5px;
  margin-right: -8px;
  border: 1px solid #fff;
  border-radius: 0;
  font-size: 12px;
}
td.order-td input:hover,
td.order-td input:focus {
  border-color: #1675e0;
}

input:hover,
input:focus {
  outline: 0;
}

.table input::placeholder {
  color: #c3c3c3;
}

tr td span.neg {
  color: #0397e0;
}

tr.header.text-error td {
  background: #f38787;
  color: #fff;
}

tr.header td {
  font-weight: 700;
  background-color: #ecf0f3;
}

tr.total td {
  position: sticky;
  bottom: 0;
  border: 0;
  font-weight: 700;
  background-color: #ecf0f3;
}

tr.separator td {
  font-weight: 700;
  color: #ffffff;
  background-color: hsl(220, 50%, 45%);
  border-color: hsl(220, 50%, 45%);
  padding: 0 10px;
}

tr.separator.separator-moneda td {
  background-color: hsl(220, 70%, 75%);
  border-color: hsl(220, 70%, 75%);
}

tr.separator.main-separator td {
  background-color: hsl(220, 40%, 10%);
  border-color: hsl(220, 40%, 10%);
}

tr.separator.sub-separator td {
  color: #575757;
  background-color: hsl(220, 15%, 85%);
  border-color: hsl(220, 15%, 85%);
}

tr.separator td span.neg {
  color: #ffffff;
}

tr.separator.green-separator td {
  background-color: #3ba340;
}
tr.separator.red-separator td {
  background-color: #da3b2d;
}

table.position td,
table.caucionable td,
table.corriente td {
  padding: 1px 10px;
  font-size: 13px;
}

table.corriente {
  table-layout: fixed;
  min-width: 1080px;
}

table.corriente tr.separator.sub-separator td,
table.caucionable tr.separator.sub-separator td {
  background-color: hsl(220, 15%, 90%);
  border-color: hsl(220, 15%, 90%);
}

table.caucionable tr td:first-of-type,
table.caucionable tr th:nth-of-type(1) {
  width: 15%;
}
table.caucionable tr td:nth-of-type(2),
table.caucionable tr th:nth-of-type(2) {
  width: 20%;
}
table.caucionable tr td:nth-of-type(3),
table.caucionable tr th:nth-of-type(3) {
  width: 15%;
}
table.caucionable tr td:nth-of-type(4),
table.caucionable tr th:nth-of-type(4) {
  width: 10%;
}
table.caucionable tr td:nth-of-type(5),
table.caucionable tr th:nth-of-type(5) {
  width: 15%;
}
table.caucionable tr td:nth-of-type(6),
table.caucionable tr th:nth-of-type(6) {
  width: 20%;
}

table.caucionable tr.separator td:first-of-type {
  width: 15%;
}
table.caucionable tr.separator td:nth-of-type(2) {
  width: 15%;
}
table.caucionable tr.separator td:nth-of-type(3) {
  width: 10%;
}
table.caucionable tr.separator td:nth-of-type(4) {
  width: 10%;
}

table.corriente tr td:first-of-type,
table.corriente tr th:nth-of-type(1) {
  width: 15%;
}
table.corriente tr td:nth-of-type(2),
table.corriente tr th:nth-of-type(2) {
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} 

table.corriente tr td:nth-of-type(3),
table.corriente tr th:nth-of-type(3) {
  width: 15%;
}
table.corriente tr td:nth-of-type(4),
table.corriente tr th:nth-of-type(4) {
  width: 15%;
}
table.corriente tr td:nth-of-type(5),
table.corriente tr th:nth-of-type(5) {
  width: 10%;
}
table.corriente tr td:nth-of-type(6),
table.corriente tr th:nth-of-type(6) {
  width: 10%;
}
table.corriente tr td:nth-of-type(7),
table.corriente tr th:nth-of-type(7) {
  width: 5%;
}

table.corriente tr.separator td:first-of-type {
  width: 15%;
}
table.corriente tr.separator td:nth-of-type(2) {
  width: 25%;
}
table.corriente tr.separator td:nth-of-type(3) {
  width: 15%;
}
table.corriente tr.separator td:nth-of-type(4) {
  width: 15%;
}
table.corriente tr.separator td:nth-of-type(5) {
  width: 10%;
}
table.corriente tr.separator td:nth-of-type(6) {
  width: 10%;
}
table.corriente tr.separator td:nth-of-type(7) {
  width: 5%;
}

table.dadi {
  table-layout: fixed;
  min-width: 1250px;

}

table.dadi tr td:first-of-type,
table.dadi tr th:nth-of-type(1) {
  width: 30%;

}
table.dadi tr td:nth-of-type(2),
table.dadi tr th:nth-of-type(2) {
  width: 15%;

} 

table.dadi tr td:nth-of-type(3),
table.dadi tr th:nth-of-type(3) {
  width: 15%;
}
table.dadi tr td:nth-of-type(4),
table.dadi tr th:nth-of-type(4) {
  width: 25%;
}
table.dadi tr td:nth-of-type(5),
table.dadi tr th:nth-of-type(5) {
  width: 15%;
}

table.emails {
  table-layout: fixed;
  min-width: 1200px;
}

table.emails tr td:first-of-type,
table.emails tr th:nth-of-type(1) {
  width: 7%;

}
table.emails tr td:nth-of-type(2),
table.emails tr th:nth-of-type(2) {
  width: 8%;
} 

table.emails tr td:nth-of-type(3),
table.emails tr th:nth-of-type(3) {
  width: 10%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
table.emails tr td:nth-of-type(4),
table.emails tr th:nth-of-type(4) {
  width: 15%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
table.emails tr td:nth-of-type(5),
table.emails tr th:nth-of-type(5) {
  width: 25%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

table.emails tr td:nth-of-type(6),
table.emails tr th:nth-of-type(6) {
  width: 35%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 1400px) {

  .actions-col {
    padding: 1px 10px;
  }

  .actions-col button {
    height: 20px;
    width: 20px;
  }

  .actions-col .rs-btn-xs.rs-btn-icon > .rs-icon {
    height: 20px;
    width: 20px;
    font-size: 10px;
  }
}
