@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  overflow-y: scroll;
  font-size: 14px;
  line-height: 1.42857143;
  color: #575757;
  background-color: #e6e7ec;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.text-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-bold td {
  font-weight: bold;
}

.text-bold {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.container {
  padding: 0 20px 20px 20px;
  width: 100%;
}

.react-datepicker-popper > div {
  transform: translateX(-110px);

}

.react-datepicker__triangle {
  left: 160px !important;
}


@media (min-width: 1200px) {
  .container {
    padding: 0 40px 10px 40px;
    width: 100%;
  }

  *:not(html):not(body)::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }

  /* Track */
  *:not(html):not(body)::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  /* Handle */
  *:not(html):not(body)::-webkit-scrollbar-thumb {
    background: rgb(195, 195, 195);
    border-radius: 4px;
  }

  /* Handle on hover */
  *:not(html):not(body)::-webkit-scrollbar-thumb:hover {
    background: rgb(175, 175, 175);
  }
}

.filepond--root.filepond--hopper{
  margin: 0;
} 