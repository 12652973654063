form:not(.form-inline) .form-group:not(:last-of-type) {
  margin-bottom: 24px;
}

.form-group.checkbox label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

select,
input,
textarea {
  width: 100%;
  outline: 0;
  display: block;
  color: #575757;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e5e5ea;
  transition: border-color ease-in-out 0.3s;
  border-radius: 6px;
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  height: 36px\9;
}

.form-group.date label > div {
  display: flex;
  border: 1px solid #e5e5ea;
  transition: border-color ease-in-out 0.3s;
  border-radius: 6px;
}

.form-group.date.error label > div {
  border-color: red;
}

.form-group.date label > div:focus-within {
  border-color: #1675e0;
}

.form-group.date label > div span {
  padding: 7px 0;
}

.form-group.date label > div input {
  border-width: 0;
  text-align: center;
}

.form-group.date input:first-child {
  width: 30px;
  padding-right: 2px;
  border-radius: 6px 0 0 6px;
}

.form-group.date input:nth-of-type(2) {
  width: 17px;
  padding: 7px 0;
  border-radius: 0;
}

.form-group.date input:nth-of-type(3) {
  width: 100%;
  padding-left: 2px;
  border-radius: 0 6px 6px 0;
  text-align: left;
}

select {
  background: #fff url(/src/assets/static/angle-arrow-down.svg) no-repeat right 0.75rem center;
  background-size: 8px 10px;
  padding-right: 30px;
  appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-group input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
  margin-top: 1px;
}

input[type="checkbox"]:disabled {
  opacity: 0.7;
}

input:disabled,
select:disabled {
  background-color: #f4f4f4;
}

input:focus,
input:hover,
select:focus,
select:hover,
textarea:focus,
textarea:hover {
  border-color: #1675e0;
  outline: 0;
}

.form-group.error input,
.form-group.error select,
.form-group.error textarea {
  border-color: hsl(0, 100%, 45%);
}

.form-group.error .error {
  position: absolute;
  color: hsl(0, 100%, 45%);
}

.user-select > div > div {
  border-color: #e5e5ea;
}

.user-select > div > div:hover {
  border-color: #1675e0;
  border-width: 1px;
}
