ul.tickets {
  padding: 5px 0;
  position: relative;
  margin: 0;
  display: grid;
  border-bottom: 1px solid #e5e7eb;
}

ul.tickets li {
  display: flex;
  padding: 5px 20px;
  border-bottom: 1px solid #e5e7eb;
  width: 100%;
}

ul.tickets li.mine {
  background-color: hsl(220, 60%, 95%);
  border-bottom: 1px solid hsl(220, 60%, 90%);
  border-top: 1px solid hsl(220, 60%, 90%);
  margin-top: -1px;
}

ul.tickets li:last-child {
  border-bottom: none;
}

ul.tickets li * {
  white-space: nowrap;
}

ul.tickets li .info {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

ul.tickets li .info h3 {
  font-size: 14px;
  width: 50px;
  margin: 0;
}

ul.tickets li .info h4 {
  margin: 0;
  margin-left: 15px;
  color: hsl(220, 10%, 50%);
  font-weight: 600;
}

ul.tickets li .to {
  color: hsl(220, 9%, 56%);
  font-weight: 600;
  width: 300px;
  display: flex;
  align-items: center;
}

ul.tickets li .to .pill {
  margin-right: 10px;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

ul.tickets li .to .pill svg {
  margin-right: 5px;
  margin-top: -1.5px;
}

ul.tickets li .to svg {
  stroke-width: 3px;
  margin-right: 5px;
}

ul.tickets li .from {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

ul.tickets li .from h5 {
  color: hsl(220, 9%, 56%);
  margin: 0;
  font-size: 11px;
}

ul.tickets li .from h5:last-child {
  font-size: 10px;
}

ul.tickets li .from h5 span {
  font-weight: 500;
  color: hsl(220, 9%, 56%);
}
