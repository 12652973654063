.card {
  position: relative;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: rgb(0, 0, 0, 0.16) 0 3px 6px;
  overflow: auto;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
}