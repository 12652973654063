.notification-alert {
  cursor: pointer;
  color: #fff;
  text-align: center;
  position: absolute;
  background-color: red;
  width: 15px;
  height: 15px;
  font-size: 12px;
  line-height: 15px;
  top: 15px;
  right: 1px;
  font-weight: 600;
  border-radius: 1000px;
}

.notification-panel {
  position: absolute;
  top: 42px;
  width: 350px;
  right: -5px;
  z-index: 4;
  transition: all ease-in-out 200ms;
}

.notification-panel > div {
  box-shadow: rgb(0 0 0 / 20%) 0 4px 7px;
  background-color: #fff;
  border-radius: 6px;
  padding: 10px 5px;
  overflow: hidden;
}

.notification-panel::before {
  content: " ";
  position: absolute;
  top: -14px;
  right: 13px;
  border: 7px #fff solid;
  border-color: transparent transparent #fff transparent;
}

.notification-panel h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  padding: 10px 15px;
  color: hsl(220, 5%, 20%);
}

.notificaciones-anuncio {
  text-align: center;
  padding: 30px;
}

.notification-container {
  cursor: pointer;
  position: relative;
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 10px;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 6px;
  transition: bacground-color ease-in-out 200ms;
}

.notification-container:hover {
  background-color: hsl(220, 40%, 95%);
}

.notification-time {
  /* position: absolute; */
  font-size: 12px;
  color: hsl(220, 5%, 60%);
  font-weight: 500;
}

.notification-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background-color: red;
  color: #fff;
  font-size: 16px;
  border-radius: 100px;
}

.notification-container.no-leido .notification-icon.message {
  background-color: hsl(220, 100%, 65%);
}

.notification-container.no-leido .notification-icon.error {
  background-color: hsl(0, 100%, 60%);
}

.notification-container .notification-icon {
  background-color: hsl(220, 10%, 75%);
}

.notification-info {
  text-align: left;
  color: hsl(220, 5%, 20%);
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.notification-info strong {
  color: hsl(220, 10%, 30%);
  font-weight: 700;
}

.notification-actions {
  position: absolute;
  bottom: 5px;
  right: 10px;
}

.notification-actions svg {
  margin-right: 3px;
  margin-top: -1px;
  font-size: 12px;
}

.notification-actions button {
  font-size: 12px;
}

.notification-actions button:hover {
  background: transparent !important;
  text-decoration: underline;
  text-decoration-color: hsl(220, 5%, 40%);
  font-size: 12px;
}
