.inbox {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
  overflow: hidden;
}

.inbox-sidebar {
  display: flex;
  flex-direction: column;
  background-color: hsl(220, 19%, 27%);
}

.inbox-sidebar-header {
  background-color: hsl(220, 19%, 24%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 60px;
}
.inbox-sidebar-header form {
  width: 100%;
}

.inbox-sidebar-header .inbox-search {
  width: 100%;
  position: relative;
}

.inbox-sidebar-header .inbox-search input {
  width: 100%;
  height: 40px;
  background-color: hsl(217, 19%, 27%);
  border: none;
  border-radius: 6px;
  padding: 0 40px 0 44px;
  outline: 0;
  transition: all 300ms ease-in-out;
  color: hsl(216, 12%, 84%);
}

.inbox-sidebar-header .inbox-search input::placeholder {
  color: hsl(216, 12%, 65%);
}

.inbox-sidebar-header .inbox-search .search-icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.inbox-sidebar-header .inbox-search .cross-icon {
  opacity: 0;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  transition: opacity 200ms ease-in-out;
  cursor: pointer;
  height: 13.5px;
  width: 13.5px;
  background-color: transparent;
  padding: 0;
  border: none;
  outline: 0;
}

.inbox-sidebar-header .inbox-search:focus-within .cross-icon {
  opacity: 1;
}

.inbox-sidebar-header .inbox-search svg > * {
  color: hsl(216, 12%, 84%);
}

.inbox-sidebar-body {
  padding: 10px;
  height: 100%;
  overflow: auto;
}

.inbox-sidebar-body .ticket-item {
  user-select: none;
  cursor: pointer;
  padding: 10px 10px 0 10px;
  border-radius: 6px;
}

.inbox-sidebar-body .ticket-item > div {
  padding: 0 0 10px 0;
  border-bottom: 1px solid hsl(220, 10%, 35%);
}

.inbox-sidebar-body h4 {
  padding: 5px 0 0 3px;
  font-weight: 600;
  margin: 0;
  color: #f1f1f1;
}

.inbox-sidebar-body .ticket-item .details {
  color: hsl(216, 12%, 65%);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.inbox-sidebar-body .ticket-item .details h6 {
  font-weight: 600;
  font-size: 13px;
  margin: 0;
}

.inbox-sidebar-body .ticket-item.active {
  padding-top: 11px;
  margin-top: -1px;
  background-color: hsl(220, 15%, 35%);
  border-bottom: 0;
}

.inbox .chat {
  background-color: hsl(220, 85%, 95%);
  display: flex;
  flex-direction: column;
}

.inbox .chat .chat-header {
  background-color: hsl(220, 5%, 97%);
  padding: 0 20px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inbox .chat .chat-header h4 {
  font-size: 15px;
}

.inbox .chat .chat-header h4 span {
  font-size: 13px;
  font-weight: 400;
  margin-right: 5px;
}

.inbox .chat .chat-body {
  padding: 20px;
  flex-grow: 1;
  overflow: auto;
}

.inbox .chat .message .user {
  display: inline-block;
  width: 80px;
  margin: 0;
  font-size: 14px;
}

.inbox .chat .message .date {
  font-weight: 500;
  font-size: 12px;
}

.inbox .chat .message .bubble {
  position: relative;
  display: block;
  width: fit-content;
  word-break: break-word;
  max-width: 80%;
  padding: 10px;
  border-radius: 0 10px 10px 10px;
  background-color: hsl(220, 60%, 50%);
  color: #fff;
  margin-bottom: 5px;
}

.inbox .chat .message.mine {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.inbox .chat .message.mine .bubble {
  background-color: hsl(220, 100%, 70%);
  color: #fff;
  border-radius: 10px 0 10px 10px;
}

.inbox .chat .message.venc .bubble {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 17px;
  min-width: 150px;
}

.inbox .chat .message.finalizado .bubble {
  background-color: hsl(145, 50%, 50%);
}

.inbox .chat .message.anulado .bubble {
  background-color: hsl(6deg 70% 60%);
}

.inbox .chat .message.venc .bubble .new-venc {
  background-color: #fff;
  color: #575757;
  font-weight: 500;
  border-radius: 1000px;
  padding: 1px 10px;
  width: fit-content;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(15px, 50%);
}

.inbox .chat .message.mine.venc .bubble .new-venc {
  left: 0;
  transform: translate(-20px, 50%);
}

.inbox .chat .chat-footer {
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inbox .chat .chat-footer button.btn-green {
  margin-left: 10px;
  width: 50%;
}

.inbox .btn-green {
  background-color: hsl(145, 40%, 50%);
}

@media (min-width: 1000px) {
  .inbox {
    grid-template-columns: 450px 1fr;
  }

  .inbox .chat .message .bubble {
    max-width: 70%;
  }
}
