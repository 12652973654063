.principal h3 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 20px 50px;
  color: #fafafa;
}

.principal h3 span {
  font-size: 20px;
  font-weight: 600;
}

.principal h3 select {
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 16px;
}

.principal .select-category {
  display: inline-block;
  margin-left: 5px;
  padding: 5px 0px;
  cursor: pointer;
  transition: border 200ms ease-in-out;
}

.principal .select-category svg {
  position: relative;
  top: 3px;
}

.bottom-section {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.bottom-section > div {
  max-height: calc((100vh - 240px) / 2 + 55px);
}

@media (min-width: 1200px) {
  .bottom-section.tickets-active {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
  }

  .bottom-section > div {
    height: calc((100vh - 240px) / 2 + 55px);
  }
}
